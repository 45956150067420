export default {
    "en": {
      "timeline$year1": "1955",
      "timeline$description1": "Horacio Pagani is born in Casilda, Argentina, on 10 November.",
      "timeline$country1": "argentina",
      "timeline$year2": "1965",
      "timeline$description2": "Based on sketches on paper, Horacio carves model cars from balsa wood.",
      "timeline$country2": "argentina",
      "timeline$year3": "1967",
      "timeline$description3": "At only 12 years of age, he creates one of his all-time favorite models: an early forerunner of the Zonda.",
      "timeline$country3": "argentina",
      "timeline$year4": "1971",
      "timeline$description4": "He builds a mini-bike using parts found in a junkyard.",
      "timeline$country4": "argentina",
      "timeline$year5": "1973",
      "timeline$description5": "Converts the old Renault Dauphine into the famous Buggy.",
      "timeline$country5": "argentina",
      "timeline$year6": "1974-76",
      "timeline$description6": "Horacio studies industrial design at the Fine Arts Department of the National University of La Plata. <br> <br>He moves to the Rosario National University to study Mechanical Engineering.<br>\nHoracio returns home to Casilda.<br><br>He builds his own “Taller”, or design studio-workshop.",
      "timeline$country6": "argentina",
      "timeline$year7": "1977-78",
      "timeline$description7": "Working to order, Horacio designs and manufactures bar furniture.<br><br>He also designs and builds caravans and camper vans using fiberglass construction techniques.",
      "timeline$country7": "argentina",
      "timeline$year8": "1979",
      "timeline$description8": "Year in which Horacio designs and builds a F2 single-seater and a camper van for Chevrolet pick-ups.",
      "timeline$country8": "argentina",
      "timeline$year9": "1981",
      "timeline$description9": "Designs fiberglass cabs for the Ford F100 pick-up, Chevrolet Cheyenne, Toyota Hilux and Peugeot 504.",
      "timeline$country9": "argentina",
      "timeline$year10": "1982",
      "timeline$description10": "Horacio designs and builds a roughness gauge for measuring road surface deformation commissioned by the Research & Development Center of the Rosario National University.",
      "timeline$country10": "argentina",
      "timeline$year11": "1983",
      "timeline$description11": "Horacio moves to Italy and starts work at Automobili Lamborghini.",
      "timeline$country11": "italy",
      "timeline$year12": "1984",
      "timeline$description12": "He is involved in the restyling work for the Jeep LM and designs the Countach Evoluzione, the first car with a carbon fiber chassis.",
      "timeline$country12": "italy",
      "timeline$year13": "1986-91",
      "timeline$description13": "Makes drawings and scale models for the Lamborghini P140 project.<br><br>Is appointed design director of the Lamborghini Countach Anniversary.<br><br>Takes part in designing and engineering the bodywork for the Lamborghini Diablo. <br><br>Works on the Lamborghini L30 project.",
      "timeline$country13": "italy",
      "timeline$year14": "1991-97",
      "timeline$description14": "Founds Modena Design.<br><br>Designs ski boots for Rossignol, Lange racing.<br><br>The complete design of the Pagani Zonda begins.<br><br>Designs interiors for Lamborghini; engineers and builds components for 250/400 Aprilia racing motorcycles (World Championship).<br><br>Engineers and builds components and dies both for F1 engines for Ferrari and parts for Berman (Suzuki Vitara, Dahiatsu Move).<br><br>Designs components and molds with advanced composite materials for Dallara F3.",
      "timeline$country14": "italy",
      "timeline$year15": "1998",
      "timeline$description15": "Founds Pagani Automobili.",
      "timeline$country15": "italy",
      "timeline$year16": "1999",
      "timeline$description16": "The Zonda C12 is unveiled at the Geneva Motor Show.",
      "timeline$country16": "italy",
      "timeline$year17": "2000",
      "timeline$description17": "Presentation of the Zonda S at the Geneva Motor Show.",
      "timeline$country17": "italy",
      "timeline$year18": "2003",
      "timeline$description18": "Presentation of the Zonda Roadster at the Geneva Motor Show.",
      "timeline$country18": "italy",
      "timeline$year19": "2005",
      "timeline$description19": "Presentation of the Zonda F at the Geneva Motor Show.",
      "timeline$country19": "italy",
      "timeline$year20": "2006",
      "timeline$description20": "Presentation of the Zonda Roadster F at the Geneva Motor Show.",
      "timeline$country20": "italy",
      "timeline$year21": "2009",
      "timeline$description21": "Presentation of the Zonda Cinque and Zonda R at the Geneva Motor Show.",
      "timeline$country21": "italy",
      "timeline$year22": "2010",
      "timeline$description22": "Presentation of the Zonda Cinque Roadster and Zonda Tricolore at the Geneva Motor Show.",
      "timeline$country22": "italy",
      "timeline$year23": "2011",
      "timeline$description23": "The Pagani Huayra is presented to the North American market.",
      "timeline$country23": "italy",
      "timeline$year24": "2013",
      "timeline$description24": "The Zonda Revolución is launched.",
      "timeline$country24": "italy",
      "timeline$year25": "2016",
      "timeline$description25": "Huayra BC is presented in Sicily.",
      "timeline$country25": "italy",
      "timeline$year26": "2017",
      "timeline$description26": "Presentation of the Zonda Roadster at the Geneva Motor Show.<br><br>The Zonda HP Barchetta is revealed at Pebble Beach.<br><br>Inauguration of the Horacio Pagani Museum and the new Production facility.",
      "timeline$country26": "italy",
      "timeline$year27": "2019",
      "timeline$description27": "The Huayra Roadster BC is presented at Pebble Beach.<br><br>The Zonda Barchetta Revo, the Barchetta for racing is launched.",
      "timeline$country27": "italy",
      "timeline$year28": "2020",
      "timeline$description28": "The Pagani Imola and Huayra Tricolore are launched.",
      "timeline$country28": "italy",
      "timeline$year29": "2021",
      "timeline$description29": "The Huayra R is launched.",
      "timeline$country29": "italy",
      "timeline$year30": "2022",
      "timeline$description30": "Huayra Codalunga, a special project is launched and a new act starts: Utopia.",
      "timeline$country30": "italy",
      "timeline$year31": "2023",
      "timeline$description31": "The Imola Roadster is launched.",
      "timeline$country31": "italy",
      "timeline$year32": "2024",
      "timeline$description32": "The Huayra R Evo is launched and the Utopia Roadster is presented at the Quail event.",
      "timeline$country32": "italy"
    },
    "it": {
      "timeline$year1": "1955",
      "timeline$description1": "Horacio Pagani nasce a Casilda, in Argentina, il 10 Novembre.",
      "timeline$country1": "argentina",
      "timeline$year2": "1965",
      "timeline$description2": "Scolpisce piccoli modelli di auto da legno di balsa, a partire da schizzi su carta.",
      "timeline$country2": "argentina",
      "timeline$year3": "1967",
      "timeline$description3": "A 12 anni costruisce uno dei modelli che predilige di più, il primo precursore della Zonda.",
      "timeline$country3": "argentina",
      "timeline$year4": "1971",
      "timeline$description4": "Costruisce una mini moto con pezzi di ricambio recuperati da un rottamaio.",
      "timeline$country4": "argentina",
      "timeline$year5": "1973",
      "timeline$description5": "Trasforma la vecchia Renault Dauphine nel famoso Buggy.",
      "timeline$country5": "argentina",
      "timeline$year6": "1974-76",
      "timeline$description6": "Studia disegno industriale alla facoltà di Belle Arti presso l’Università Nazionale di La Plata.<br><br>Studia Ingegneria Meccanica all’Università Nazionale di Rosario.<br><br>Torna a Casilda.<br><br>Costruisce il suo “Taller”, ovvero l’atelier-laboratorio.",
      "timeline$country6": "argentina",
      "timeline$year7": "1977-78",
      "timeline$description7": "Progetta e produce mobili per bar, i primi lavori su commissione. <br><br>Progetta e costruisce caravan e roulotte utilizzando tecniche di costruzione in vetroresina.",
      "timeline$country7": "argentina",
      "timeline$year8": "1979",
      "timeline$description8": "Progetta e costruisce una monoposto di F2 e un camper van per pick-up Chevrolet.",
      "timeline$country8": "argentina",
      "timeline$year9": "1981",
      "timeline$description9": "Disegna cabine in vetroresina per Ford F100 pick-up, Chevrolet Cheyenne, Toyota Hilux, Peugeot 504.",
      "timeline$country9": "argentina",
      "timeline$year10": "1982",
      "timeline$description10": "Studia e costruisce un misuratore di rugosità da utilizzare per la deformazione stradale, commissionato dal Centro di Ricerca e Sviluppo dell’Università di Rosario.",
      "timeline$country10": "argentina",
      "timeline$year11": "1983",
      "timeline$description11": "Si trasferisce in Italia e inizia a lavorare presso Automobili Lamborghini.",
      "timeline$country11": "italy",
      "timeline$year12": "1984",
      "timeline$description12": "Collabora al restyling della Jeep LM e progetta la Countach Evoluzione, la prima vettura con telaio in fibra di carbonio.",
      "timeline$country12": "italy",
      "timeline$year13": "1986-91",
      "timeline$description13": "Realizza disegni e modelli in scala per il progetto Lamborghini P140.<br><br>Viene nominato responsabile del design della Lamborghini Countach Anniversary.<br><br>Collabora al design e all’ingegnerizzazione della carrozzeria della Lamborghini Diablo.<br><br>Lavora al progetto Lamborghini L30.",
      "timeline$country13": "italy",
      "timeline$year14": "1991-97",
      "timeline$description14": "Fonda Modena Design.<br><br>Progetta scarponi da sci Rossignol, Lange racing.<br><br>Inizia il progetto completo di Pagani Zonda.<br><br>Disegna interni per Lamborghini; ingegnerizza e costruisce componenti per moto da corsa 250/400 Aprilia (Campionato del Mondo).<br><br>Ingegnerizza e costruisce componenti e stampi sia di motori F1 per Ferrari sia di parti per Berman (Suzuki Vitara, Dahiatsu Move).<br><br>Progetta componenti e stampi con materiali compositi avanzati per Dallara F3.",
      "timeline$country14": "italy",
      "timeline$year15": "1998",
      "timeline$description15": "Fonda Pagani Automobili.",
      "timeline$country15": "italy",
      "timeline$year16": "1999",
      "timeline$description16": "Presentazione di Zonda C12 al Salone dell’Automobile di Ginevra.",
      "timeline$country16": "italy",
      "timeline$year17": "2000",
      "timeline$description17": "Presentazione di Zonda S al Salone dell’Automobile di Ginevra.",
      "timeline$country17": "italy",
      "timeline$year18": "2003",
      "timeline$description18": "Presentazione di Zonda Roadster al Salone dell’Automobile di Ginevra.",
      "timeline$country18": "italy",
      "timeline$year19": "2005",
      "timeline$description19": "Presentazione di Zonda F al Salone dell’Automobile di Ginevra.",
      "timeline$country19": "italy",
      "timeline$year20": "2006",
      "timeline$description20": "Presentazione di Zonda Roadster F al Salone dell’Automobile di Ginevra.",
      "timeline$country20": "italy",
      "timeline$year21": "2009",
      "timeline$description21": "Presentazione di Zonda Cinque e Zonda R al Salone dell’Automobile di Ginevra.",
      "timeline$country21": "italy",
      "timeline$year22": "2010",
      "timeline$description22": "Presentazione di Zonda Cinque Roadster e Zonda Tricolore al Salone dell’Automobile di Ginevra.",
      "timeline$country22": "italy",
      "timeline$year23": "2011",
      "timeline$description23": "Presentazione di Pagani Huayra al mercato nordamericano.",
      "timeline$country23": "italy",
      "timeline$year24": "2013",
      "timeline$description24": "Lancio di Zonda Revolución.",
      "timeline$country24": "italy",
      "timeline$year25": "2016",
      "timeline$description25": "Presentazione di Huayra BC in Sicilia.",
      "timeline$country25": "italy",
      "timeline$year26": "2017",
      "timeline$description26": "Lancio di Huayra Roadster al Salone dell’Automobile di Ginevra.<br><br>Presentazione di Zonda HP Barchetta a Pebble Beach.<br><br>Inaugurazione del Horacio Pagani Museo e del nuovo Atelier di produzione.",
      "timeline$country26": "italy",
      "timeline$year27": "2019",
      "timeline$description27": "Presentazione di Huayra Roadster BC a Pebble Beach.<br><br>Presentazione di Zonda Barchetta Revo, la Barchetta da Pista.",
      "timeline$country27": "italy",
      "timeline$year28": "2020",
      "timeline$description28": "Lancio di Pagani Imola and Huayra Tricolore.",
      "timeline$country28": "italy",
      "timeline$year29": "2021",
      "timeline$description29": "Lancio di Huayra R.",
      "timeline$country29": "italy",
      "timeline$year30": "2022",
      "timeline$description30": "Lancio del progetto speciale Huayra Codalunga e inizia un nuovo capitolo: Utopia.",
      "timeline$country30": "italy",
      "timeline$year31": "2023",
      "timeline$description31": "Lancio di Imola Roadster.",
      "timeline$country31": "italy",
      "timeline$year32": "2024",
      "timeline$description32": "Lancio di Huayra R Evo e presentazione di Utopia Roadster a Quail.",
      "timeline$country32": "italy"
    }
  }